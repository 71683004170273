.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

h1 {
    margin-top: 0;
}

form > div, form > div > div {
    margin-bottom: 15px;
}

form label {
    display: block;
    margin-bottom: 5px;
}

form input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

form button {
    padding: 10px 20px;
    background-color: #2980B9;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}

form button:hover {
    background-color: #3498DB;
}
