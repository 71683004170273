@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.topbar {
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #ccc;
}

.department-name {
    cursor: pointer;
}

.department-name:hover h1 {
    color: #241d81 !important;
}

.topbar .department-name:hover img {
    filter: brightness(0) saturate(100%) invert(25%) sepia(44%) saturate(340%) hue-rotate(206deg) brightness(83%) contrast(90%);
}

.min-margin-bottom {
    margin-bottom: 1px;
}

.department-name h1 {
    margin: 0;
    font-size: 18px;
    color: #4539F2;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
}

.user-info {
    display: flex;
    align-items: center;
}

.user-info div {
    margin: 5px;
}

.user-info span {
    margin-right: 1rem;
}

.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}

.sidebar {
    width: 250px;
    background-color: #333;
    color: #fff;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar li {
    padding: 10px 20px;
    border-bottom: 1px solid #555;
}

.sidebar li:last-child {
    border-bottom: none;
}

.sidebar a {
    color: #fff;
    text-decoration: none;
}

.sidebar a:hover {
    background-color: #555;
}

.main-panel {
    margin-left: 250px;
    padding: 1rem;
}

.font-weight-normal {
    font-weight: 500 !important;
}

.invalid{
    color: #DC3545;
    font-size: 14px;
}