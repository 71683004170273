body, html {
    margin: 0 !important;
    box-sizing: border-box !important;
}

html {
    overflow-y: scroll !important;
    padding: 0 !important;
}

body {
    padding-right: 10px !important;
    background: #F9F9F9;
}

::-webkit-scrollbar {
    width: 5px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #c5c5c5;
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #7c7c7c;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

.custom-modal-header {
    background-color: #ffffff !important;
    padding-bottom: 2px;
    border: none !important;
}

.custom-modal-title {
    font-family: DM Sans !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    text-decoration: none !important;
}

.custom-modal-content {
    padding: 16px !important;
    margin: 0px !important;
    border-bottom: none !important;
}

.custom-modal-footer {
    border-top: none !important;
}

.custom-label {
    font-family: 'DM Sans', sans-serif !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    color: #626D7C;
}

.custom-submit-button {
    color: #F0F7FF !important;
    background-color: #3259E8 !important;
    border-radius: 8px !important;
    font-family: 'Inter', sans-serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
}

.wrap-sidebar {
    padding-left: 8px;
    padding-right: 8px;
}

.sidebar-wrapper {
    top: 0;
    left: 0;
    /*min-width: 240px;*/
    padding-top: 40px;
    border-radius: 0 16px 16px 0;
    background-color: #bebebe;
    font-family: 'DM Sans', sans-serif !important;
    color: #2d2d2d !important;
    min-height: 100vh;
    position: sticky;
    z-index: 20;
}

.sidebar-wrapper .nav-link {
    color: #2d2d2d;
    text-align: left;
}

.sidebar-wrapper .nav-link .wrap-sider-icon-logo {
    display: flex;
    padding: 0;
    align-items: center;
    margin-right: 5px;
}

.sidebar-wrapper .nav-link .wrap-sider-icon-logo img {
    padding-right: 3px;
    margin: auto;
    width: 16px;
}

.sidebar-menu {
    background-color: #bebebe;
    color: #2d2d2d !important;
    margin-bottom: 1px;
    margin-top: 1px;
}

.sidebar-menu:hover {
    background: #d1d1d1 !important;
    color: #020202 !important;
    border-radius: 3px;
}

.main-content {
    padding: 20px;
    padding-right: 80px;
    background: #F9F9F9;
}

.wrap-sidebar-logo {
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 43px;
    display: flex;
    text-align: center;
    justify-content: center;
}

.sidebar-logo {
    width: 50%;
}

.page-title {
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: #1B174F;
    text-align: left;
}

.content-page {
    padding: 24px;
}

.wrap-table-tools {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 15px;
    margin-top: 25px;
    min-width: 40px;
    height: 50px;
}

.wrap-page {
    margin-top: 30px;
}

.wrap-title-page {
    margin-bottom: 30px;
}

.search-block {
    display: flex;
    align-items: center;
}

.button-block {
    display: flex;
    align-items: center;
}

.custom-tab-item {
    width: 130px !important;
}

.thead-table {
    background: #EDF1FF;
    border-radius: 10px 10px 0 0;
}

.thead-table th {
    text-wrap: nowrap;
    background: #EDF1FF;
    font-size: 16px;
    font-weight: 500;
    padding: 16px 12px 16px 12px;
    color: #191919;
}

.thead-table th:first-child {
    border-radius: 10px 0 0 0;
}

.thead-table th:last-child {
    border-radius: 0 10px 0 0;
}

.thead-table-global-config {
    background: #EDF1FF;
    border-radius: 10px 10px 0 0;
}

.thead-table-global-config th {
    font-family: 'DM Sans';
    color: #191919;
    text-wrap: nowrap;
    background: #EDF1FF;
    font-size: 16px;
    font-weight: 500;
    padding: 16px 12px 16px 12px;
}

.list-table tbody tr:last-child td:first-child {
    border-radius: 0px 0px 0px 10px;
}

.list-table tbody tr:last-child td:last-child {
    border-radius: 0px 0px 10px 0px;
}

.table-head-tools {
    background: white;
    background-color: inherit;
    cursor: default;
    height: 47px;
}

.selected-side-menu {
    background: #959595 !important;
    color: #FFFFFF !important;
    border-radius: 3px;
    margin-bottom: 1px;
    margin-top: 1px;
}

.selected-side-menu img {
    filter: sepia(1) saturate(1000%) hue-rotate(10deg) brightness(90%) !important;
}

.wrap-side-menu-item img {
    filter: contrast(0) brightness(90%);
}

.wrap-side-menu-item {
    padding-left: 10px;
}

.selected-side-menu-icon {
    background: #5a5a5a;
    width: 2px;
    height: 24px;
    border-radius: 0px 8px 8px 0px;
}

.side-menu-icon {
    width: 2px;
    height: 24px;
    border-radius: 0px 8px 8px 0px;
}

.wrap-table {
    position: relative;
    margin-top: 10px;
    overflow-x: auto;
    min-height: 65vh;
}

.list-table {
    overflow: unset;
    border: 1px solid #E5EFFF !important;
}

.list-table td:first-child,
.list-table th:first-child {
    padding-left: 15px;
}

.list-table td:last-child,
.list-table th:last-child {
    padding-right: 15px;
}

.list-table td {
    max-width: 250px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #545454;
}

.list-table tr:first-child td {
    align-content: center;
    overflow: unset !important;
}

.list-table input {
    padding: 0px;
}

.list-table select {
    /* padding-left: 0px; */
}

.table-head-tools td {
    background: white;
    padding: 4px 12px 4px 0px;
}

.list-table .table-head-tools td {
    background: white;
    padding: 2px 12px 2px 0px;
}

.list-table .table-head-tools td input,
.list-table .table-head-tools td select,
.list-table .table-head-tools td .select__placeholder,
.list-table .table-head-tools img {
    font-family: 'DM Sans' !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #666E7D !important;
    opacity: 1 !important;
}

.table-head-tools td input, .table-head-tools td .custom-select {
    padding-left: 12px;
}

.table-head-tools:hover {
    background-color: white !important;
    cursor: default;
    --bs-table-bg-state: white !important;;
}

.table-head-tools td:hover {
    background-color: white !important;
    cursor: default;
}

.list-table tbody > tr:first-child:hover > * {

    /* background-color: inherit !important;
     --bs-table-bg-state: inherit !important; */
}

.list-table tbody .table-head-tools:hover > * {
    background-color: inherit !important;
    --bs-table-bg-state: inherit !important;
}

table {
    font-family: 'DM Sans';
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #DEE6FF;
    text-align: left;
}

.list-table td {
    font-family: 'DM Sans';
    line-height: 24px;
    font-size: 14px;
    font-weight: 400;
    color: #545454;
    padding: 16px 12px 16px 12px;
}

.filter-in-table {
    font-family: 'DM Sans';
    color: #666E7D;
    height: 32px;
    font-size: 14px;
    line-height: 18px;
    border: none;
    width: auto;
}

.filter-in-table:focus {
    outline: none !important;
    box-shadow: none !important;
}


.filter-up-table {
    font-family: 'DM Sans';
    color: #666E7D;
    height: 32px;
    font-size: 14px;
    line-height: 18px;
    max-width: 300px;
}

.filter-up-table-second {
    font-family: 'DM Sans';
    color: #666E7D;
    height: 32px;
    font-size: 14px;
    line-height: 18px;
}

.filter-up-table:focus, .filter-up-table-second:focus {
    outline: none !important;
    box-shadow: none !important;
}

.button-custom {
    font-family: 'DM Sans';
    font-weight: 500;
    height: 40px;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 24px 7px 24px;
    border-radius: 8px;
}

.button-blue {
    display: flex;
    align-items: center;
    /*padding: 8px 24px 7px 16px !important;*/
    background: #4B4EFC;
    font-family: 'Inter', sans-serif !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    align-items: center;
}

.button-custom > img {
    margin-right: 8px;
    width: 24px;
}

.button-purple {
    background: #3027AC;
    font-family: 'Inter', sans-serif !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
}

.label-in-modal {
    font-family: 'DM Sans';
    font-weight: 400;
    color: #666E7D;
    font-size: 12px;
    margin-bottom: 3px;
}

.select-in-modal {
    font-family: 'DM Sans';
    font-weight: 500;
    color: #494A5B;
    font-size: 14px;
    padding: 9px 16px 9px 16px;
    border-color: #D8D9DF;
}

.button-save {
    background: #3259E8;
    padding: 13px 32px 13px 32px;
    font-family: 'Inter', sans-serif !important;
    font-size: 16px;
    font-weight: 700;
    border-radius: 8px;
    width: 100%;
}

.icon-file-extension {
    width: 36px;
}

.icon-file-name {
    word-wrap: break-word;
    max-width: 380px;
    text-wrap: wrap;
    font-family: 'DM Sans';
    font-weight: 500;
    color: #000000;
    font-size: 16px;
}

.icon-file-name-extension {
    font-family: 'DM Sans';
    font-weight: 500;
    color: #626D7C;
    font-size: 14px;
}

.label-value-in-modal {
    font-family: 'DM Sans';
    font-weight: 500;
    color: #494A5B;
    font-size: 16px;
    margin-bottom: 3px;
}

.wrap-button-row {
    display: flex;
    justify-content: flex-end;
}

.wrap-button-row img {
    margin-right: 5px;
}

.wrap-button-row img:last-of-type {
    margin-right: 1px;
}

.wrap-button-row img:hover {
    cursor: pointer;
    background: #f3f3f3;
    border-radius: 5px;
}

.wrap-text {
    overflow-wrap: anywhere;
}

.small-label {
    font-size: 13px !important;
}

.button-close {
    color: #F0F7FF;
    background: #3027AC;
    padding: 13px 32px 13px 32px;
    font-family: 'Inter', sans-serif !important;
    font-size: 16px;
    font-weight: 700;
    border-radius: 8px;
    width: 100%;
    border: none;
}

.button-edit {
    color: #000000;
    padding: 13px 32px 13px 32px;
    font-family: 'Inter', sans-serif !important;
    font-size: 16px;
    font-weight: 700;
    border-radius: 8px;
    width: 100%;
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
}

.custom-add-label:hover {
    color: #241d81 !important;
}

.sort-th {
    cursor: pointer;
}

.sort-th:hover img {
    color: black;
    filter: brightness(0) saturate(100%);
}

.button-delete {
    max-width: 153px;
    color: #F0F7FF;
    background: #DC3C26;
    padding: 13px 32px 13px 32px;
    font-family: 'Inter', sans-serif !important;
    font-size: 16px;
    font-weight: 700;
    border-radius: 8px;
    width: 100%;
    border: none;
}

.button-cancel {
    max-width: 153px;
    color: #626D7C;
    background: #E2E5EB;
    padding: 13px 32px 13px 32px;
    font-family: 'Inter', sans-serif !important;
    font-size: 16px;
    font-weight: 700;
    border-radius: 8px;
    width: 100%;
    border: none;
}

.text-in-delete-modal {
    font-family: 'DM Sans';
    font-weight: 500;
    color: #494A5B;
    font-size: 16px;
    margin-bottom: 40px;
}

.loader-overlay {
    left: 0;
    position: absolute;
    width: 100%;
    height: 99%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.38);
    z-index: 5;
    border-radius: 10px;
    padding: 50px;
    margin: 0;
}

.relative {
    position: relative;
}

.loader {
    background: rgba(75, 78, 252, 0.05);
    color: rgba(48, 39, 172, 0.53);
    width: 40px;
    height: 40px;
    position: fixed;
    top: 40%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrap-login-page {
    height: 100vh;
    background: #F7F8FB;
}

.login-page {
    margin: 0 auto;
    background: #F7F8FB;
    max-width: 516px;
    padding-top: 90px;
}

.wrap-login-logo {
    margin: auto;
    max-width: 243px;
    padding-bottom: 43px;
}

.wrap-login-form {
    background: white;
    border: 1px solid #DEE6FF;
    border-radius: 16px;
}

.label-login-text {
    font-family: 'DM Sans';
    font-weight: 700;
    color: #1B174F;
    font-size: 34px;
    text-align: center;
}

.label-no-page-text {
    font-family: 'DM Sans';
    font-weight: 700;
    color: #1B174F;
    font-size: 34px;
    text-align: center;
}

.label-login-small-text {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #626D7C;
    font-size: 16px;
    text-align: center;
}

.label-no-page-small-text {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #626D7C;
    font-size: 16px;
    text-align: center;
}

.login-input {
    max-width: 436px;
    width: 100%;
    border: 1px solid #DEE6FF;
    border-radius: 8px;
    padding: 20px;
    padding-left: 40px;
    font-family: 'DM Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #626D7C;
}

.input-wrapper {
    position: relative;
    width: 100%;
}

.icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    pointer-events: none;
}

.icon-right {
    position: absolute;
    left: 90%;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    pointer-events: none;
    z-index: 9999999;
    pointer-events: auto;
}

.label-login-forgot-pass {
    font-family: 'DM Sans';
    font-weight: 400;
    color: #626D7C;
    font-size: 16px;
    text-align: right;
    text-decoration: underline;
}

.login-button {
    width: 100%;
    max-width: 450px;
    border-radius: 8px;
    padding: 14px 20px 14px 20px;
    background: #3259E8;
    font-family: 'Inter', sans-serif !important;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    border: none;
}

.login-form {
    padding: 40px;
}

.label-login-dont-have-auth-pass {
    font-family: 'DM Sans';
    font-weight: 400;
    color: #626D7C;
    font-size: 16px;
    text-align: center;
    padding: 24px;
}

.label-login-sign-up {
    font-family: 'DM Sans';
    font-weight: 500;
    color: #626D7C;
    font-size: 16px;
    text-align: center;
    text-decoration: underline;
}

.status-green {
    text-align: center;
    max-width: 150px;
    width: fit-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: #C6EDDF;
    padding: 2px 6px 2px 6px;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #00825D;
}

.status-grey {
    text-align: center;
    max-width: 150px;
    width: fit-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: #EDEFF5;
    padding: 2px 6px 2px 6px;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #696F8C;
}

.status-red {
    text-align: center;
    max-width: 150px;
    width: fit-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: #FFDEDE;
    padding: 2px 6px 2px 6px;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #D14343;
}

.select-pagination-per-page {
    height: 100%;
    font-family: 'DM Sans' !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    background: white;
    color: #051B44;
    border-radius: 4px;
    padding: 0px 8px 0px 8px;
}

.select-pagination-per-page button {
    background: white !important;
    color: #051B44 !important;
    border: none !important;
    font-family: 'DM Sans' !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.pagination .page-item .page-link {
    color: #051B44;
    font-family: 'DM Sans' !important;
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    height: 32px;
}

.pagination .page-item.active .page-link {
    color: white !important;
}

.pagination .page-item .page-link span img {
    vertical-align: sub
}

.tracker-block {
    display: flex;
}

.time-block {
    display: flex;
    justify-content: left;
    width: 95px;
    margin: auto 0 !important;
    background: #45C368;
    padding: 3px 12px 3px 13px;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: #FFFFFF;
}

.stop-block {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.stop-icon {
    display: flex;
    margin: auto;
    width: 23px;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
}

.stop-icon img {
    width: 15px;
}

.stop-icon:hover img {
    cursor: pointer;
    width: 23px;
}

.label-end {
    width: 75px;
    margin-left: 1px !important;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: #CD375C;
}


.tracker-disable {
    background: #cccdd3;
}

.start-block {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.start-icon {
    display: flex;
    margin: auto;
    width: 23px;
    text-align: center;
    align-items: center;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
}

.start-icon img {
    width: 15px;
}

.start-icon:hover img {
    cursor: pointer;
    width: 23px;
}

.label-start {
    width: 75px;
    margin-left: 1px !important;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: #188A42;
}

.logout-button:hover {
    cursor: pointer;
}

.select-is-invalid > div {
    border-color: #dc3545 !important;
    border: none !important;
    box-shadow: 0 0 0 1px #dc3545 !important;
}

.select-is-invalid > div:active {
    border-color: #dc3545 !important;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25) !important;
}

.block-role-item {
    width: 347px;
    border-radius: 6px;
    padding: 24px;
    background: white;

    margin-right: 10px;
    margin-bottom: 10px;

    font-family: 'DM Sans';
    font-weight: 500;
    color: #1B174F;
    font-size: 18px;
    line-height: 24px;
}

.block-role-item > div:first-child {
    padding-bottom: 15px;
    border-bottom: 1px solid #D8D9DF;
}

.block-role-item > div:last-child {
    padding-top: 30px;
}

.wrap-icon-role {
    margin-right: 20px;
    max-width: 48px;
}

.role-icons-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.role-icon {
    position: absolute;
    transition: left 0.3s ease;
}

.role-icon img {
    margin-right: 20px;
    max-width: 48px;
    width: 40px;
    height: 40px;
}

.role-icon .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #4B4EFC !important;
    font-family: 'DM Sans';
    font-weight: 500;
    color: white;
    font-size: 16px;
    line-height: 21px;
}

.custom-add-label {
    font-family: 'DM Sans';
    font-weight: 500;
    color: white;
    font-size: 14px;
    line-height: 18px;
    color: #4539F2;
    text-decoration: none !important;
}


.custom-remove-label {
    font-family: 'DM Sans';
    font-weight: 500;
    color: white;
    font-size: 14px;
    line-height: 18px;
    color: #EF5944;
    text-decoration: none !important;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-none {
    cursor: default !important;
}

.no-hover-button {
    pointer-events: none !important;
}

.left-block-table {
    border-radius: 10px 0px 0px 10px !important;
    width: 240px;
    color: #4C5563;
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.left-block-table td {
    background: #DEE6FF;
    padding-left: 10px !important;
}

.left-block-table .table-head-tools td {
    background: #DEE6FF;
    padding-left: 10px !important;
}

.selected-block {
    color: #1B174F !important;
    background: #C3CFFF !important;
}

.selected-block div {
    color: #1B174F !important;
    font-weight: 500;
}

.left-block-table tr td:hover {
    background: #cfd8fd !important;
    --bs-table-bg-state: #C3CFFF !important;
    color: #1B174F;
}

.left-block-table tr:first-child td:first-child {
    border-radius: 10px 0 0 0 !important;
}

.left-block-table tr:last-child td:first-child {
    border-radius: 0 0 0 10px !important;
}

.right-block-table thead tr:first-child th:last-child {
    border-radius: 0 10px 0 0 !important;
}

.right-block-table tbody tr:last-child td:first-child {
    border-radius: 0px !important;
}

.left-block-table tbody > tr > .first-col-left-table:hover {
    background: #d5deff !important;
    --bs-table-bg-state: #d5deff !important;
    color: #1B174F;
    font-weight: 500;
    cursor: pointer;
}

.form-check-input:hover {
    cursor: pointer;
}

.right-block-table {
    border-radius: 0px 10px 10px 0px !important;
    width: 100%;
}

.right-block-table tr > td:first-child {
    width: 50%;
}

.left-block-table td, .right-block-table td {
    height: 57px;
    vertical-align: middle;
    padding: 12px 16px
}

.right-block-table tbody tr:hover {
    background: white !important;
    --bs-table-bg-state: white !important;
}

.right-block-table tr:hover td {
    --bs-table-bg-state: white !important;
}

.wrap-left-table-icon-logo {
    color: #4C5563;
}

.wrap-left-table-icon-logo > img {
    filter: brightness(0) saturate(100%) invert(28%) sepia(8%) saturate(346%) hue-rotate(169deg) brightness(94%) contrast(85%);
}

.select__multi-value-wrapper, .select__value-container {
    display: flex !important;
    /*  flex-wrap: nowrap !important; */
    overflow-x: auto !important;
    max-width: 100% !important;
}

.select__value-container {
    /*  height: 50px !important; */
}

.select__multi-value {
    margin-right: 8px !important;
    min-width: auto !important;
    white-space: nowrap !important;
}

.select__multi-value__label {
    white-space: nowrap !important;
}

.custom-select {
    font-family: 'DM Sans';
    color: #666E7D;
    min-height: 30px;
    font-size: 14px;
    line-height: 18px;
    border-color: #D8D9DF;
    width: auto;
    text-align: left;
    z-index: 10 !important;
}

.custom-select-in-modal > div:first-of-type {
    padding: 0px 8px 0px 8px !important;
    border-color: #D8D9DF;
    border-radius: 0.375rem;
}

.select-without-border {
    border: none !important;
    box-shadow: none !important;
    border-width: 0px !important;
}

.select-without-border > div:first-of-type {
    border: none !important;
    box-shadow: none !important;
    border-width: 0px !important;
}

.custom-select .select__value-container {
    height: 30px !important;
}

.custom-multi-select {
    font-family: 'DM Sans';
    color: #666E7D;
    min-height: 30px;
    font-size: 14px;
    line-height: 18px;
    border-color: #D8D9DF !important;
    width: auto;
}

.custom-multi-select .select__value-container {
    /*  height: 30px !important; */
    max-height: 60px;
    overflow-y: scroll;
}

.select__control:hover {
    border-color: #D8D9DF !important;
}

.custom-multi-select:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #D8D9DF !important;
}

.cancel-button {
    color: #3259E8 !important;
    background-color: #DEE6FF !important;
    border-radius: 8px !important;
    font-family: 'Inter', sans-serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    border: none !important;
}

.save-button {
    color: #FFFFFF !important;
    background-color: #4B4EFC !important;
    border-radius: 8px !important;
    font-family: 'Inter', sans-serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    border: none !important;
}

.table-head-tools .react-datepicker-wrapper {
    width: 185px !important;
}

.table-head-tools .basic-multi-select {
    width: 200px !important;
    border: none !important;
    box-shadow: none !important;
}


.table-head-tools .basic-multi-select .select__control {
    border: none !important;
    box-shadow: none !important;
}

.table-head-tools .basic-multi-select .select__control--is-focused {
    border: none !important;
    box-shadow: none !important;
}

.table-head-tools .basic-multi-select .select__value-container,
.table-head-tools .basic-multi-select .css-t3ipsp-control .css-hlgwow,
.table-head-tools .select-without-border .css-hlgwow,
.table-head-tools .basic-multi-select .select__value-container .css-t3ipsp-control .css-hlgwow {
    padding: 0px !important;
}

.table-head-tools .react-datepicker-wrapper > div {
    width: 100% !important;
}

.table-head-tools .react-datepicker-wrapper > div > input {
    width: 100% !important;
}

.wrap-user-image {
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: relative;
}

.custom-wrap-small-image {
    width: 48px !important;
    height: 48px !important;
    border-radius: 52px;
}

.user-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: block;
    border-radius: 100px;
}

.user-image-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 110px;
}

.user-image-block [role="presentation"] {
    cursor: pointer;
}

.custom-bold-text {
    color: #545454 !important;
    text-wrap: nowrap !important;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.tracker-table {
    border-bottom: 0px;
}

.tracker-table tr:last-child td {
    border-bottom: none;
}

.time-table {
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
}

.time-table td {
    border: 1px solid #E5EFFF;
    padding: 8px 30px 8px 30px;
    width: 50%;
}

.background-table-yellow {
    background: #FFFFE7;
    color: #545454;

    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.background-table-blue {
    background: #EDF1FF;
    color: #545454;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.toast-container {
    color: white;
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 999999999;
}

.charts-container {
    display: flex;
    flex-wrap: wrap;
    padding: 25px;
}

.charts-container > div {
    margin: 5px;
}

.chart-wrapper {
    width: 520px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px
}

.manage-button {
    padding: 13px 32px 13px 32px;
    background: #3259E8;
    color: white;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    line-height: 16px;
    font-weight: 700;
    border: none;
    width: 470px;
}

.header-chart {
    border-bottom: 1px solid #ccc;
    padding: 15px;
    display: flex;
    justify-content: space-between;
}

.title-chart {
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: #1E1B39;
}

.big-data-chart {
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 44px;
    line-height: 52px;
    color: #1E1B39;
}

.small-data-chart {
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #615E83;
}

.event-dot {
    width: 100%;
    max-width: 8px !important;
    height: 8px !important;
    border-radius: 50%;
    margin: 0 5px;
    background: #73AB37;
}

.color-red {
    background: red !important;
}

.chart-small-label {
    font-family: 'DM Sans', sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #626D7C;
}

.chart-bold-label {
    font-family: 'DM Sans', sans-serif !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    flex-wrap: wrap;
    color: #000000;
}

.view-switcher .btn {
    background-color: #e6eaff;
    border: none !important;
    color: #555;
    font-weight: 500;
}

.view-switcher .btn.active {
    background-color: #ffffff;
    color: #000;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border: none !important;
}

.view-switcher .btn:hover {
    background-color: #d9e0f0;
    color: #000;
    border: none !important;
}

.view-switcher .btn:focus {
    background-color: #d9e0f0;
    color: #000;
    border: none !important;
}

.view-switcher .btn {
    background-color: #e6eaff;
    border: none !important;
    color: #555;
    font-weight: 500;
}

.view-switcher .btn-primary {
    background-color: #e6eaff;
    border: none !important;
}


.view-switcher .btn.active,
.view-switcher .btn:focus,
.view-switcher .btn-check:checked + .btn,
.view-switcher .btn:checked {
    background-color: #ffffff !important;
    color: #000 !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border: none !important;
}

.view-switcher .btn:hover {
    background-color: #d9e0f0;
    color: #000;
    border: 1px solid #EDEDF1;
}

.wrap-chart {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-top: 10px;
}

.nav-underline .nav-link.active, .nav-underline .show > .nav-link {
    color: #3259E8;
}

.nav-underline .nav-link {
    font-family: 'DM Sans', sans-serif !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #626D7C;
}

.wrap-requests-block {
    border: 1px solid #EDEDF1;
    background-color: #EDF1FF;
    padding: 20px;
    margin-bottom: 20px;
}

.img-avatar-request {
    object-fit: cover;
    width: 58px;
    height: 58px;
    border-radius: 4px;
}

.request-block {
    margin-bottom: 30px;
}

.title-request-block {
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #4C5563;
    text-align: left;
}

.time-request-block {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #626D7C;
    text-align: left;
}


.requests-block-info {
    width: 550px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #DEDEDE;
    gap: 10px;
    padding: 16px;
}

.requests-block-info-continue {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #B6B6B6;
    text-align: left;
}

.requests-block-info-title {
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    text-align: left;
}

.requests-block-info-text {
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #494A5B;
    text-align: left;
    word-wrap: break-word;
}

.approve-button {
    background: #2A5082;
    border-radius: 8px;
    padding: 10px 32px 10px 32px;
    border: none !important;

    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: white;
}

.decline-button {
    background: white;
    border-radius: 8px;
    padding: 10px 32px 10px 32px;
    border: none !important;

    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #858585;
}

.load-more-button {
    border: 1px solid #9EAFFF;
    background: none;
    border-radius: 5px;
    padding: 8px;
    width: 100%;

    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #3A2CD6;
}

.load-more-button:hover {
    background: #2A5082;
}

.multi-approve-decline-block {
    display: flex;
    background: white;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #DEE6FF;
    border-radius: 8px;
}

.multi-select-info {
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #191919;
}

.approve-green-button {
    background: #45C368;
    border-radius: 8px;
    border: none;
    padding: 8px 24px 8px 24px;

    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #F0F7FF;
}

.decline-red-button {
    background: #DC3C26;
    border-radius: 8px;
    border: none;
    padding: 8px 24px 8px 24px;

    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #F0F7FF;
}

.img-avatar-table {
    object-fit: cover;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 12px;
    margin-bottom: 3px;
}

.inverse-image {
    transform: rotate(180deg);
}

.label-total-time {
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #626D7C;
}

.disabled-button {
    transform: none !important;
    cursor: progress !important;
    pointer-events: none;
}

/*
.select__value-container::-webkit-scrollbar {
    height: 2px !important;
    width: 1px !important;
}

.select__value-container::-webkit-scrollbar-thumb {
    background-color: #888888FF !important;
    width: 1px !important;
}

.select__value-container::-webkit-scrollbar-track {
    background-color: #f5f5f5 !important;
}
 */
